import axios, { AxiosResponse } from 'axios'
import Vue from 'vue'
import { auth } from '../config/firebaseConfig'
import {
  isInvalidCsrfTokenError,
  isUnauthorizationError,
} from '../lib/exception-handler'
import { store } from '../store'

axios.defaults.baseURL =
  import.meta.env.VITE_API_URL || 'https://yookgak.com/api'
axios.defaults.withCredentials = true
axios.defaults.xsrfCookieName = 'X-CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-TOKEN'

Vue.prototype.$axios = axios

axios.interceptors.request.use((config) => {
  store.commit('startLoading')

  if (!config['axios-retry']) {
    config['axios-retry'] = {
      retries: 2,
    }
  }

  return config
})

async function handleRetry(response: AxiosResponse) {
  const { config } = response
  if (config['axios-retry'].retries > 0) {
    config['axios-retry'].retries--

    if (isInvalidCsrfTokenError(response)) {
      await store.dispatch('csrfToken')
      return axios(config)
    } else if (isUnauthorizationError(response)) {
      const idToken = await auth.currentUser?.getIdToken()
      if (idToken) {
        config.headers['X-User-Id-Token'] = idToken
        return axios(config)
      } else {
        store.dispatch('logout')
      }
    }

    return axios(config)
  } else if (isUnauthorizationError(response)) {
    store.dispatch('logout')
  }

  console.error('axios error', response.data)
  return Promise.reject(response)
}

axios.interceptors.response.use(
  (response) => {
    store.commit('stopLoading')

    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      return handleRetry(response)
    }
  },
  async (error) => {
    store.commit('stopLoading')

    if (error.response) {
      if (error.response.status === 304) {
        return Promise.resolve(error.response)
      }

      return handleRetry(error.response)
    } else {
      console.error('axios error', error)
      return Promise.reject(error)
    }
  },
)

export default axios
