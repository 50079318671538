import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import 'firebase/compat/storage'

firebase.initializeApp(JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG))

export const db = firebase.database()
export const fstore = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
