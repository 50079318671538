import axios from 'axios'
import { differenceInMilliseconds } from 'date-fns'
import { User } from 'firebase/auth'
import { connect } from 'socket.io-client'
import Vue from 'vue'
import Vuex from 'vuex'
import { auth, db, fstore } from './config/firebaseConfig'
import { serverTimeDate } from './return/serverTimeDate'

Vue.use(Vuex)

let categoryListUnsubscribe: VoidFunction | null = null

const preloadSocket = connect('https://serve.yookgak.com/preload', {
  transports: ['websocket'],
})
preloadSocket.emit('init_preload_data', 'test')

function mobileAndTabletcheck() {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substring(0, 4),
      )
    )
      check = true
  })(navigator.userAgent)

  return check
}

export const store = new Vuex.Store({
  state: {
    isMobile: mobileAndTabletcheck(),
    faq: null,
    faqCategories: null,
    goodsList: null,
    goodsDetail: null,
    goodsInfo: null,
    planInfo: null,
    mainEvent: null,
    mainNotice: null,
    routerRecord: null,
    historyBack: null,
    historyFull: [],
    customerData: null,
    customerRef: null,
    isFirstgift: null,
    isRecommendFriend: null,
    bannerFlag: true,
    timeDiff: 0,

    loading: 0,
    uid: null,
    loggedIn: false,

    webGtag: {
      webutm_campaign: null,
      webutm_source: null,
      webutm_content: null,
      webutm_medium: null,
      webutm_term: null,
      webga: null,
    },
    categoryList: null,
    productCouponList: null,
  },
  getters: {
    getRouterRecord: (state) => {
      return state.routerRecord
    },

    getHistoryBack: (state) => {
      return state.historyBack
    },

    getCustomerData: (state) => {
      return state.customerData
    },

    getFirstgiftFlag: (state) => {
      return state.isFirstgift
    },
    isLoading({ loading }) {
      return loading > 0
    },
  },
  mutations: {
    startLoading(state) {
      state.loading++
    },
    stopLoading(state) {
      if (state.loading > 0) {
        state.loading--
      }
    },

    login(state, payload) {
      state.uid = payload.uid
      state.loggedIn = true
    },
    logout(state) {
      state.uid = null
      state.loggedIn = false
    },

    setRecordRouter(state, router) {
      state.routerRecord = router
    },

    setHistoryBack(state, router) {
      state.historyBack = router
    },

    setCustomerData(state, data) {
      state.customerData = data
    },

    setFirstgiftFlag(state, flag) {
      state.isFirstgift = flag
    },

    setCategoryList(state, data) {
      state.categoryList = data
    },

    setProductCouponList(state, data) {
      state.productCouponList = data
    },
  },
  actions: {
    async clientInit({ commit, dispatch }) {
      async function updateToken(user: User) {
        if (user) {
          commit('login', user)
          const idToken = await user.getIdToken()
          await dispatch('setAuthenticateHeader', idToken)
        } else {
          commit('logout')
          await dispatch('setAuthenticateHeader', null)
        }
      }

      auth.onIdTokenChanged(updateToken)
      auth.onAuthStateChanged(updateToken)

      return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe()
          resolve(user)
        }, reject)
      })
    },

    logout({ commit }) {
      auth.signOut().then(() => {
        commit('logout')
      })
    },

    async getServerTime({ state }) {
      const result = await fetch(
        'https://serve.yookgak.com/api/v1/get-datetime',
        {
          method: 'GET',
        },
      )
      const data = await result.json()
      state.timeDiff = differenceInMilliseconds(new Date(data), new Date())
    },

    setAuthenticateHeader(_, token) {
      if (token) axios.defaults.headers.common['X-User-Id-Token'] = token
      else delete axios.defaults.headers.common['X-User-Id-Token']
    },
    async csrfToken() {
      const res = await axios.get('/v1/csrf-token')
      axios.defaults.headers.common['X-CSRF-TOKEN'] = res.data.data.token
    },
    async preloadGoodsList({ state }) {
      const result = await fetch('https://serve.yookgak.com/preload-data', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const data = await result.clone().json()

      state.goodsList = data.firestore.goodslist.value
      state.goodsDetail = data.firestore.goodsdetail.value
      state.planInfo = data.firebase['angular/plan_info'].value
      state.mainEvent = data.firebase['support/mainevent'].value
      state.mainNotice = data.firebase['support/mainnotice'].value
      // state.notice = data.firebase['support/notice'].value;
    },

    fetchGoodsList({ state }) {
      preloadSocket.on('goodslist', (data) => {
        state.goodsList = data
      })
    },

    fetchGoodsDetail({ state }) {
      preloadSocket.on('goodsdetail', (data) => {
        state.goodsDetail = data
      })
    },

    fetchGoodsInfo({ state }) {
      preloadSocket.on('goods_info', (data) => {
        state.goodsInfo = data
      })
    },

    fetchMainEvent({ state }) {
      preloadSocket.on('mainevent', (data) => {
        state.mainEvent = data
      })
    },

    fetchMainNotice({ state }) {
      preloadSocket.on('mainnotice', (data) => {
        state.mainNotice = data
      })
    },

    async fetchFaq({ state }) {
      const faqSnaps = await fstore.collection('support/faq/web').get()
      const faqCategories = await fstore
        .collection('support/faq/categories')
        .get()

      const faqList = []
      faqSnaps.forEach((faq) => {
        if (faq.data().publish) {
          faqList.push(faq.data())
        }
      })
      faqList.sort((a, b) => a.order - b.order)

      const faqCategoriesList = []
      faqCategories.forEach((fc) => {
        const data = fc.data()
        data.unique_key = fc.id
        if (data.publish) {
          faqCategoriesList.push(data)
        }
      })

      faqCategoriesList.sort((a, b) => a.order - b.order)

      state.faq = faqList
      state.faqCategories = faqCategoriesList
    },

    fetchCustomerData({ state, commit }, uid) {
      return new Promise((resolve) => {
        if (state.customerRef) state.customerRef.off()
        state.customerRef = db.ref('customers/' + uid)
        state.customerRef.on('value', (customerSnaps) => {
          commit('setCustomerData', customerSnaps.val())
          resolve(null)
        })
      })
    },

    async checkFirstgiftTarget({ commit }, { uid, due }) {
      if (uid) {
        const userInfoSnap = await fstore
          .doc(`/customers/user/information/${uid}`)
          .get()

        const userInfo = userInfoSnap.data()

        if (!(userInfo && typeof userInfo.last_order_id === 'number')) {
          const firstgiftEndDate = userInfo.signup_timestamp.toDate()
          firstgiftEndDate.setDate(firstgiftEndDate.getDate() + due)
          firstgiftEndDate.setHours(23)
          firstgiftEndDate.setMinutes(59)

          const currentDate = serverTimeDate()
          currentDate.setHours(currentDate.getHours() - 9)

          const changeDate = new Date('2023-01-09')
          changeDate.setHours(12)
          changeDate.setMinutes(0)
          changeDate.setSeconds(0)

          const firstgiftDistance = differenceInMilliseconds(
            firstgiftEndDate,
            currentDate,
          )
          if (due === 30) {
            const signupDate = userInfo.signup_timestamp.toDate()
            const changeDistance = differenceInMilliseconds(
              changeDate,
              signupDate,
            )

            if (changeDistance >= 0) {
              commit('setFirstgiftFlag', false)
            } else {
              commit('setFirstgiftFlag', firstgiftDistance >= 0)
            }
          } else {
            commit('setFirstgiftFlag', firstgiftDistance >= 0)
          }
        } else {
          commit('setFirstgiftFlag', false)
        }
      } else {
        commit('setFirstgiftFlag', true)
      }
    },

    getCategoryList({ commit }) {
      return new Promise((resolve) => {
        if (categoryListUnsubscribe) categoryListUnsubscribe()
        categoryListUnsubscribe = fstore
          .collection('applications/goods/categorylist')
          .onSnapshot((categorySnaps) => {
            const categoryData = []
            categorySnaps.forEach((category) => {
              categoryData.push(category.data())
            })
            commit('setCategoryList', categoryData)
            resolve(true)
          })
      })
    },
  },
})
