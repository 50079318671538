import axios from 'axios'
import { auth } from '../config/firebaseConfig'

class AppService {
  async verifyCustomToken(token: string, os: string) {
    const response = await axios.get(
      '/v1/customer/verify-custom-token?token=' + token + '&os=' + os,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )

    const result = response.data
    const giftUrl = 'https://gift.jeongyookgak.com'

    if (result['code'] === '0000') {
      return `${giftUrl}?token=${result.response.custom_token}`
    }
    return giftUrl
  }
}

export const appService = new AppService()
